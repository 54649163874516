import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import Linktree from "./pages/linktree/Linktree";
import NotFound from "./pages/notFound/NotFound";
import { WebsiteProvider } from "./context/WebsiteContext";
import Admin from "./pages/Admin/Admin";
import SignIn from "./pages/SignIn/SignIn";
import PrivateRoute from "./routes/PrivateRoute";
import Melon from "./pages/notFound/Melon";

const App = () => {
  const isAuthenticated = false;
  return (
    <Router>
      <WebsiteProvider>
        <Switch>
          <Route path="/" exact component={Melon} />
          {/* <Route path="/" exact component={Linktree} /> */}
          {/* <PrivateRoute path="/admin" component={Admin} />
          <Route
            path="/signin"
            render={() =>
              isAuthenticated ? <Redirect to="/admin" /> : <SignIn />
            }
          /> */}
          <Route path="/watermelon" component={NotFound} />
          <Route path="*" render={() => <Redirect to="/watermelon" />} />
        </Switch>
      </WebsiteProvider>
    </Router>
  );
};

export default App;
